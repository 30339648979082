import { fairyDustCursor } from "cursor-effects";
import TypeIt from "typeit";
import 'iconify-icon';


new fairyDustCursor({
    colors: ["#FF76D3", "#CC73FE", "#A588FF", "#78B6FE"],
});

function getRandomColor() {
    color = "hsl(" + Math.random() * 360 + ", 70%, 75%)";
    return color;
  }

document.addEventListener("DOMContentLoaded", function() {
    new TypeIt("#headline", {
        speed: 200,
        loop: true,
        afterStep: function (instance) {
            instance.getElement().style.color = getRandomColor();
        },
    })
    .pause(100)
    .delete(10)
    .type("umbreonben :)")
    .pause(200)
    .delete(13)
    .type("umbreonben :3")
    .pause(400)
    .delete(13)
    .type("umbreonben ^^")
    .pause(300)
    .delete(13)
    .type("umbreonben :P")
    .go();
});